<!-- 설비계통 관리 상세 (EquipSysMgmtDetail) -->
<template>
    <div class="detail-container">
        <div v-if="detailData" style="height:100%; width:100%;">
            <!-- 노드 기본 정보 -->
            <div class="detail-header">
                <!-- Badge & Control Buttons -->
                <div class="row d-flex justify-content-between align-items-center">
                    <div class="col-sm-6 d-flex align-items-center">
                        <span class="badge title1">
                            {{ detailData.sysNodeName }}
                        </span>
                        <span class="badge title2">
                            {{ detailData.sysNodeIdx }}
                        </span>
                        <span class="badge others">
                            {{ detailData.sysType ? systemTypeList.find(item => item.sysType ==
                                detailData.sysType).sysTypeName : "-" }}
                        </span>
                        <span class="badge others">
                            {{ detailData.equipType ? equipTypeList.find(item => item.equipType ==
                                detailData.equipType).equipTypeName : "-" }}
                        </span>
                        <span class="badge others">
                            {{ detailData.nodeType ? commonCodes.nodeType.find(item => item.value ==
                                detailData.nodeType).text : '-'
                            }}
                        </span>
                    </div>

                    <div class="btn-group mr-2 ">
                        <button class="btn btn-sm btn-danger" @click="deleteNode">삭제</button>
                        <button class="btn btn-sm btn-primary" @click="saveNode">저장</button>
                    </div>
                </div>

                <!-- Node Basic Info -->
                <div class="form-row">
                    <div class="col-auto my-1">
                        <label for="nodeName" class="col-form-label">노드명(*):</label>
                        <input v-model="detailData.sysNodeName" type="text" class="form-control" id="nodeName">
                    </div>
                    <div class="col-auto my-1">
                        <label for="zone" class="col-form-label">구역:</label>
                        <select v-model="detailData.zoneIdx" class="form-control" id="zone">
                            <option :value="null">구역 선택</option>
                            <option v-for="(zone) in zoneInfo" :key="zone.value" :value="zone.value">
                                {{ zone.text }}
                            </option>
                        </select>
                    </div>
                    <div v-if="detailData.nodeType == 'Equip'" class="col-auto my-1">
                        <label for="equip" class="col-form-label">설비:</label>
                        <input v-model="detailData.equipName" type="text" class="form-control" id="equip" disabled>
                    </div>
                    <div v-if="detailData.nodeType == 'Equip'" class="col-auto my-1">
                        <label for="equipIdx" class="col-form-label">설비 ID:</label>
                        <div class="input-group">
                            <input type="text" class="form-control" id="equipIdx" v-model="detailData.equipIdx">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" v-b-toggle.sidebar
                                    @click="showSideBar">선택</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto my-1">
                        <label for="enabled" class="col-form-label">사용 여부:</label>
                        <select v-model="detailData.enabled" class="form-control" id="enabled">
                            <option :value="null">사용여부선택</option>
                            <option v-for="(enable) in commonCodes.enabled" :key="enable.value" :value="enable.value">
                                {{ enable.text }}
                            </option>
                        </select>
                    </div>
                    <div v-if="systemType.sumAvail == 'Y'" class="col-auto my-1">
                        <label class="col-form-label" for="sumEnabled">계통 집계 활성화 여부</label>
                        <select v-model="detailData.sumEnabled" id="sumEnabled" class="form-control">
                            <option :value="null">선택</option>
                            <option v-for="(value) in commonCodes.enabled" :key="value.value" :value="value.value">
                                {{ value.text }}
                            </option>
                        </select>
                    </div>
                </div>
                <div v-if="systemType.sumAvail == 'Y'" class="form-row">
                    <div class="col-auto my-1">
                        <label class="col-form-label" for="sumMethod">노드 집계방법</label>
                        <select v-model="detailData.sumMethod" id="sumMethod" class="form-control">
                            <option :value="null">선택</option>
                            <option v-for="(value) in commonCodes.sumMethod" :key="value.value" :value="value.value">
                                {{ value.text }}
                            </option>
                        </select>
                    </div>
                    <div class="col-auto my-1">
                        <label class="col-form-label" for="sumUnitType">집계값 유형</label>
                        <select v-model="detailData.sumUnitType" id="sumUnitType" class="form-control">
                            <option :value="null">선택</option>
                            <option v-for="(unitType) in $store.state.unitTypes" :key="unitType.value"
                                :value="unitType.value">
                                {{ unitType.text }}
                            </option>
                        </select>
                    </div>
                    <div class="col-auto my-1">
                        <label class="col-form-label" for="sumUnit">집계값 단위</label>
                        <select v-model="detailData.sumUnit" id="sumUnit" class="form-control">
                            <option :value="null">선택</option>
                            <option v-for="(unit) in $store.state.unitCodes[detailData.sumUnitType]" :key="unit.value"
                                :value="unit.value">
                                {{ unit.text }}
                            </option>
                        </select>
                    </div>
                    <div class="col-auto my-1">
                        <label class="col-form-label" for="sumCondType">집계 조건 유형</label>
                        <select v-model="detailData.sumCondType" id="sumCondType" class="form-control">
                            <option :value="null">선택</option>
                            <option v-for="(type) in commonCodes.sumCondType" :key="type.value" :value="type.value">
                                {{ type.text }}
                            </option>
                        </select>
                    </div>
                    <div class="col-auto my-1">
                        <label class="col-form-label" for="sumCond">집계 조건문</label>
                        <input v-model="detailData.sumCond" id="sumCond" class="form-control"
                            :disabled="detailData.sumCondType !== 'Input'" />
                    </div>
                </div>
            </div>

            <!-- 하위그룹/설비정보/연관관제점 탭 영역 -->
            <div class="tab-detail-content" :style="{ height: detailData.nodeType == 'Equip' ? '80%' : '85%' }">
                <b-card no-body>
                    <b-tabs card>
                        <b-tab v-if="detailData.nodeType == 'Root'" 
                        :title="`하위 계통(${detailData.subNodes.filter(node => node.nodeType == 'System').length})`">
                            <SubSystemPanel ref="SubSystemPanel" :data="detailData" />
                        </b-tab>
                        <b-tab v-if="detailData.nodeType !== 'Equip'"
                        :title="`하위 그룹(${detailData.subNodes.filter(node => node.nodeType == 'Group').length})`">
                            <SubGroupPanel ref="SubGroupPanel" :data="detailData" />
                        </b-tab>
                        <b-tab v-if="detailData.nodeType == 'Group'"
                        :title="`하위 설비(${detailData.subNodes.filter(node => node.nodeType == 'Equip').length})`">
                            <SubEquipPanel ref="SubEquipPanel" :data="detailData" />
                        </b-tab>
                        <b-tab v-if="detailData.nodeType == 'Equip'" title="설비 정보">
                            <!-- <EquipInfoPanel ref="EquipInfoPanel" :data="detailData" /> -->
                            <EquipInfoDetail ref="EquipInfoDetail" :title="''" :equipType="equipType"
                                :equipIdx="equipIdx" :type="'global'" />
                        </b-tab>
                        <b-tab :title="`연관 관제점(${detailData.mappedPoints.length})`">
                            <RelationPointPanel ref="RelationPointPanel" :data="detailData" />
                        </b-tab>
                    </b-tabs>
                </b-card>
            </div>
        </div>

        <!-- 빈 계통 알림 영역 -->
        <div v-else-if="!detailData && isSystemEmpty" class="detail-placeholder">
            <span class="detail-notification-icon">
                <i class="fas fa-external-link-alt"></i>
            </span>
            <h5 class="mb-4">선택한 계통을 사용하려면 계통을 활성화해야 합니다.</h5>
            <button type="button" class="btn btn-sm btn-warning" @click="initNode">계통 활성화</button>
        </div>

        <!-- 계통 선택 알림 -->
        <div v-else class="detail-placeholder">
            <span class="detail-notification-icon">
                <i class="fas fa-external-link-alt"></i>
            </span>
            <h5>설비 그룹 또는 설비를 선택해주세요.</h5>
        </div>

        <!-- 서브 팝업 -->
        <b-sidebar ref="equipSideBar" id="sidebar" bg-variant="light" backdrop-variant="secondary" width="33vw"
            @hidden="closeSidebar" no-header shadow right backdrop>
            <div v-if="sideBar" style="padding: 2rem; height: 100%">
                <EquipSearch ref="equipSearch" />
            </div>
            <template #footer>
                <div class="d-flex align-items-center" style="padding: 1rem; flex: 1;">
                    <button type="button" class="btn btn-sm btn-primary" style="flex: 1; margin-right: 0.5rem"
                        @click="setNewEquipInfo">저장</button>
                    <button type="button" class="btn btn-sm btn-secondary" style="flex: 1; margin-left: 0.5rem;"
                        @click="closeSidebar">취소</button>
                </div>
            </template>
        </b-sidebar>

    </div>
</template>

<script>
import SubSystemPanel from './panel/SubSystemPanel.vue'
import SubGroupPanel from './panel/SubGroupPanel.vue';
import SubEquipPanel from './panel/SubEquipPanel.vue';
// import EquipInfoPanel from './panel/EquipInfoPanel.vue';
import RelationPointPanel from './panel/RelationPointPanel.vue';
import EquipSearch from '@src/views/component/v2.1/PointSearch/EquipSearch.vue'
import EquipInfoDetail from '@src/views/new/management-info/equip-info/EquipInfoDetail.vue'
import { mapState } from 'vuex';

export default {
    components: {
        SubSystemPanel,
        SubGroupPanel,
        SubEquipPanel,
        // EquipInfoPanel,
        RelationPointPanel,
        EquipSearch,
        EquipInfoDetail,
    },
    props: ["isSystemEmpty"],
    created() { },
    mounted() {
    },
    beforDestroyed() { },
    data() {
        return {
            rows: [],
            columns: [],
            detailData: null,
            groupData: null,
            equipData: [],
            sideBar: false,
            equipIdx: null,
            equipType: null,
        }
    },
    computed: {
        ...mapState({
            commonCodes: state => state.commonCodes
        }),
        ...mapState({
            systemTypeList: state => state.systemTypeList
        }),
        ...mapState({
            equipTypeList: state => state.equipTypeList
        }),
        ...mapState({
            zoneInfo: state => state.zoneInfo
        }),
        systemType() {
            return this.$store.state.systemTypeList.find(type => type.sysType == this.detailData.sysType)
        }
    },
    watch: {
        detailData: {
            immediate: true,
            async handler(newVal) {
                if (newVal) {
                    this.$nextTick(() => {
                        if (newVal.nodeType !== 'Equip') this.$refs.SubGroupPanel.setGroupData(newVal);
                        if (newVal.nodeType == 'Root') this.$refs.SubSystemPanel.setSystemData(newVal);
                        if (newVal.nodeType == 'Group') this.$refs.SubEquipPanel.setEquipData(newVal);
                        if (newVal.nodeType == 'Equip') this.setEquipInfoData(newVal);
                        this.$refs.RelationPointPanel.setRltnData(newVal);
                    })
                }
            }
        },
    },
    methods: {
        setEquipInfoData(data) {
            this.equipIdx = data.equipIdx;
            this.equipType = data.equipType;
            this.$nextTick(() => {
                this.$refs['EquipInfoDetail'].loadData();
            })
        },
        initData() {
            return {
                sysNodeName: "",
                nodeType: "",
                sysType: "",
                equipType: "",
                zoneIdx: null,
                equipIdx: null,
                description: null,
                enabled: "",
                sumEnabled: null,
                sumUnitType: null,
                sumUnit: null,
                sumCondType: null,
                sumCond: null,
                mappedPoints: [],
                inheritPoints: []
            }
        },
        setData(data) {
            if (data) {
                this.detailData = data;
            } else {
                this.detailData = this.initData();
            }
        },
        clearData() {
            this.detailData = null;
        },
        deleteNode() {
            this.$emit('delete')
        },
        showSideBar() {
            this.sideBar = true;
        },
        closeSidebar() {
            this.$refs.equipSideBar.hide();
            this.sideBar = false;
        },
        async setNewEquipInfo() {
            let select = await this.$refs.equipSearch.selectedEquip();
            if (select.length == 1) select = { ...select[0] }
            if (select) {
                this.setEquipInfoData(select);
            }
            this.closeSidebar();
        },
        async saveNode() {
            let groupData = this.$refs.SubGroupPanel?.groupData ?? [];
            let equipData = this.$refs.SubEquipPanel?.equipData ?? [];

            if (this.detailData.subNodes.length == 0 && this.detailData.nodeType !== 'Root') {
                if (groupData.length > 0) {
                    groupData.forEach(item => {
                        this.detailData.subNodes.push(item)
                    })
                }
                if (equipData.length > 0) {
                    equipData.forEach(item => {
                        this.detailData.subNodes.push(item)
                    })
                }
            }

            if (this.detailData.subNodes.length > 0 && this.detailData.nodeType !== 'Root') {
                let checkGroup = this.detailData.subNodes.every(item => item.nodeType == 'Group')
                if (!checkGroup) {
                    groupData.forEach(item => {
                        this.detailData.subNodes.push(item)
                    })
                }
                let checkEquip = this.detailData.subNodes.every(item => item.nodeType == 'Equip');
                if (!checkEquip) {
                    equipData.forEach(item => {
                        this.detailData.subNodes.push(item)
                    })
                }
            }

            let rltnPoint = this.$refs.RelationPointPanel.rltnPointRowData;
            if (rltnPoint) {
                this.detailData.mappedPoints = rltnPoint;
            }
            if (this.detailData.nodeType == 'Equip') {
                let equipInfo = this.$refs.EquipInfoDetail.detailInfo;
                this.detailData.equipIdx = equipInfo.equipIdx
            }
            await this.$emit("save", this.detailData)
        },
        initNode() {
            this.$emit("init")
        },
    },
}

</script>

<style scoped>
.row {
    width: 100%;
}

.form-row {
    width: 100%;
}

.card {
    height: 100%;
    width: 100%;
}

.col-auto {
    flex: 1;
}

.detail-container {
    height: 100%;
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    border-radius: 10px;
    font-size: 12px;
}

.detail-placeholder {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.detail-header {
    padding: 8px 16px 8px 16px;
    margin-bottom: 0px !important;
    min-height: 140px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 6px 6px 0px 0px;
    border: solid #ececec 1px;
    border-bottom: 1px solid #ccc;
    background-color: #f1f1f1;

    font-weight: bold;
}

.detail-header .detail-title {
    padding: 0 !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.tab-detail-content {
    width: 100%;
    padding: 20px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    background-color: white;
    border: solid #ececec 1px;
    border-radius: 0px 0px 6px 6px;
}

.detail-itemview {
    /* height: 200px; */
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;

    justify-content: center;
    align-items: center;

    border-radius: 0px 0px 6px 6px;
    border: solid #ececec 1px;
}

.detail-control {
    margin-bottom: 8px !important;
    display: flex;
    justify-content: flex-end;
}

.detail-menu {
    font-size: 0.8rem;
    font-weight: bold;
}

.detail-bottom {
    margin-top: auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 40 px;
}

.detail-bottom-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.detail-btn {
    /* height: 50px; */
    flex: 1;
    margin: 0px 4px 0px 4px;
}

.detail-bottom-info {
    height: 20px;
    margin: 4px 8px 4px 8px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title-icon {
    font-size: 1rem !important;
}

.input-form {
    width: 100%;
    /* 부모 요소에 꽉 맞춤 */
    box-sizing: border-box;
    /* 입력 폼 요소의 패딩과 보더를 포함하여 크기를 계산 */
}

.input-form input {
    width: 100%;
    /* 입력 요소가 부모 요소에 꽉 맞춤 */
    padding: 10px;
    /* 선택적으로 패딩 설정 */
    margin-bottom: 10px;
    /* 선택적으로 아래쪽 여백 추가 */
}


.collapsed>.when-opened,
:not(.collapsed)>.when-closed {
    display: none;
}

.detail-notification-icon {
    padding: 1rem;
    color: #444;
    font-size: 48px;
}

.detail-notification-text {
    font-size: 15px;
    font-weight: bold;
}


.title {
    /* font-size: 14px; */
    font-weight: 600;
    margin-right: 20px;
}

.badge {
    font-size: 12px;
    font-weight: 500;
    margin-right: 4px;
    display: inline-block;
    min-width: 20px;
    padding: 0px 10px 0px 10px;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    /* background-color: #777; */
    line-height: 22px;
    border-radius: 8px;
}

.badge.title1 {
    background-color: #FF6D00;
    color: #fff;
}

.badge.title2 {
    background-color: #546E7A;
    color: #fff;
}

.badge.others {
    background-color: #CFD8DC;
    color: #000;
}

.status-icon {
    font-size: 16px;
    color: #546E7A;
    background-color: #ECEFF1;
    border: solid #B0BEC5 1px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.2rem;
    transition: background-color 0.5s;
    width: 2rem;
    height: 2rem;
}

.status-icon:hover {
    /* color: #546E7A; */
    background-color: #B0BEC5;
}

.status-icon.active {
    color: #ECEFF1;
    background-color: #546E7A;
}
</style>